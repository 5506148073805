import { FC } from 'react';
import { Navigate } from 'react-router-dom';

import { Box, Button, Flex, Grid, Text } from '@chakra-ui/react';
import routes from 'routes';

import CardPlaceholder from 'components/builder/CardPlaceholder';
import PlayerCard from 'components/builder/PlayerCard';
import { BuilderProps } from 'components/pages/BuilderPage/types';

import { ReactComponent as LogoIcon } from 'icons/logo-bg.svg';

const Builder: FC<BuilderProps> = ({
  cardsWithStatus,
  selectedCards,
  card,
  contest,
  benchCard,
  chooseCard,
  animation,
  player,
  participation,
  isAnimating,
  showTutorial,
}) => {
  const restCardsLength = 7 - cardsWithStatus.length;
  const canCut = restCardsLength > 3 - selectedCards.length + (benchCard ? 0 : 1);
  const isDisabled = !card || isAnimating;

  if (contest && contest.status !== 'active') {
    return <Navigate to={routes.home} replace />;
  }

  if (participation && participation?.status !== 'cards_pending') {
    return <Navigate to={routes.squad} replace />;
  }

  if (!participation) {
    return null;
  }

  return (
    <Box px="12px" pt="8px">
      <Grid gridTemplateRows="auto 1fr auto auto" h="100%">
        <Grid gridTemplateColumns="48px 1fr 48px" gap="8px" py="8px">
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box
              onClick={showTutorial}
              height="36px"
              width="36px"
              borderRadius="50%"
              backgroundColor="#111111"
              display="flex"
              alignItems="center"
              justifyContent="center"
              fontSize="20px"
            >
              ?
            </Box>
          </Box>
          <Box textAlign="center">
            <Text fontSize="18px" fontWeight="800" lineHeight="21.6px">
              Build your squad
            </Text>
            <Text variant="text1" mt="4px">
              Earn from total PTS and boosts
            </Text>
          </Box>
          <Box></Box>
        </Grid>

        <Box pos="relative">
          <Box
            pos="absolute"
            pointerEvents="none"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            as={LogoIcon}
          />
          <Flex py="16px" pos="relative" height="100%" justify="center">
            {card && player && (
              <PlayerCard detailed card={card} height="100%" animation={`${animation} 0.6s forwards`} />
            )}
          </Flex>
        </Box>
        <Flex justify="space-between" textAlign="center" textTransform="uppercase" mt="11px">
          <Box>
            <Text mb="12px" variant="text2" color="white">
              CARDS
            </Text>
            <Text fontWeight="500" fontFamily="JetBrains Mono" fontSize="40px" pt="12px">
              {restCardsLength}
            </Text>
          </Box>
          <Box>
            <Text mb="12px" variant="text2" color="white">
              STARTERS
            </Text>
            <Flex gap="8px">
              <CardPlaceholder card={selectedCards[0]} />
              <CardPlaceholder card={selectedCards[1]} />
              <CardPlaceholder card={selectedCards[2]} />
            </Flex>
          </Box>
          <Box>
            <Text mb="12px" variant="text2" color="white">
              BENCH
            </Text>
            <CardPlaceholder card={benchCard} />
          </Box>
        </Flex>
        <Flex justify="center" gap="8px" py="24px">
          <Button
            flex="1"
            isDisabled={selectedCards.length >= 3}
            onClick={() => {
              if (!isDisabled) {
                chooseCard(card, 'start');
              }
            }}
          >
            start
          </Button>
          <Button
            flex="1"
            isDisabled={!!benchCard}
            onClick={() => {
              if (!isDisabled) {
                chooseCard(card, 'bench');
              }
            }}
          >
            bench
          </Button>
          <Button
            flex="1"
            isDisabled={!canCut}
            onClick={() => {
              if (!isDisabled) {
                chooseCard(card, 'cut');
              }
            }}
          >
            cut
          </Button>
        </Flex>
      </Grid>
    </Box>
  );
};

export default Builder;
