const routes = {
  home: '/',
  builder: '/builder',
  signIn: '/signin',
  leaderboard: '/leaderboard',
  boosters: '/boosters',
  squad: '/squad',
  allBoosters: '/all-boosters',
  logout: '/logout',
};

export default routes;
