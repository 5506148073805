import { FC, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Box, Fade, Grid, SlideFade } from '@chakra-ui/react';
import { Booster } from 'models';
import routes from 'routes';

import BoosterCard from 'components/booster/BoosterCard';

import BoosterCardSkeleton from './BoosterCardSkeleton';

type Props = {
  boosters: Booster[] | undefined;
  title: string;
  subTitle: string;
  onSelect?: (booster: Booster) => void;
  isLoading?: boolean;
};
const Boosters: FC<Props> = ({ boosters, onSelect, title, subTitle, isLoading }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean[]>([]);

  useEffect(() => {
    if (boosters) {
      const openBoosters = async () => {
        for (let i = 0; i < boosters.length; i++) {
          await new Promise((resolve) => setTimeout(resolve, 100));
          setIsOpen((prev) => {
            const newIsOpen = [...prev];
            newIsOpen[i] = true;
            return newIsOpen;
          });
        }
      };
      openBoosters();
    }
  }, [boosters]);

  return (
    <Box>
      <Fade in={true}>
        <Box pos="relative" textAlign="center" p="14px 0 4px">
          <Box fontSize="18px" fontWeight="800" lineHeight="21.6px">
            {title}
          </Box>
          <Box fontSize="14px" fontWeight="500" lineHeight="15.4px" textAlign="center" mt="4px">
            {subTitle}
          </Box>
          <Box
            pos="absolute"
            right="16px"
            top="50%"
            transform="translateY(-50%)"
            fontSize="14px"
            fontWeight="500"
            as={Link}
            to={onSelect ? routes.squad : routes.home}
          >
            close
          </Box>
        </Box>
      </Fade>

      {(isLoading || !boosters) && (
        <Grid mt="20px" templateColumns="repeat(auto-fill, 170px)" gap="11px" justifyContent="center">
          {Array.from({ length: 6 }).map((_, index) => (
            <BoosterCardSkeleton key={index} />
          ))}
        </Grid>
      )}
      {boosters && boosters.length > 0 && (
        <Grid mt="20px" templateColumns="repeat(auto-fill, 170px)" gap="11px" justifyContent="center">
          {boosters.map((booster, index) => (
            <SlideFade key={booster.id} in={isOpen[index]} offsetY="10px">
              <BoosterCard
                booster={booster}
                cursor="pointer"
                onClick={() => {
                  onSelect?.(booster);
                  onSelect && navigate(routes.squad);
                }}
              />
            </SlideFade>
          ))}
        </Grid>
      )}

      {boosters && boosters.length === 0 && (
        <Box
          maxW="268px"
          w="100%"
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          fontSize="14px"
          fontWeight="500"
          textAlign="center"
          lineHeight="16.8px"
        >
          No boosters available. Come up for daily spins, shop, whatever.
        </Box>
      )}
    </Box>
  );
};

export default Boosters;
