import { Flex } from '@chakra-ui/react';

import { ReactComponent as ClutchpointsIcon } from 'icons/clutchpoints.svg';

const SquadFooter = () => {
  return (
    <Flex justify="center" align="center" mt="20px" mb="34px">
      <ClutchpointsIcon />
    </Flex>
  );
};

export default SquadFooter;
