import { FC, useMemo } from 'react';

import { Box, Flex } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { LeaderboardStat } from 'models';

import { ReactComponent as LeftArrowIcon } from 'icons/left-arr.svg';
import { ReactComponent as RightArrowIcon } from 'icons/right-arr.svg';

import LeaderboardHeaderSkeleton from './LeaderboardHeaderSkeleton';

type Props = {
  stat?: LeaderboardStat;
  onNext(id: number): void;
  onPrev(id: number): void;
  disabled: boolean;
};

const LeaderboardHeader: FC<Props> = ({ disabled, stat, onNext, onPrev }) => {
  const dateStr = useMemo(() => {
    if (!stat) return '';
    const startsAtNewYork = dayjs(stat.currentContest.startsAt).tz('America/New_York');
    return startsAtNewYork.format('MMM D');
  }, [stat]);

  if (!dateStr) return <LeaderboardHeaderSkeleton />;

  return (
    <Flex
      top="0"
      justify="center"
      flexDir="column"
      align="center"
      fontSize="14px"
      lineHeight="16.8px"
      pos="sticky"
      padding="10px 18px 10px"
      mt="8px"
      bg="#242424"
      zIndex="10"
    >
      <Box fontWeight="800">{dateStr}</Box>
      <Box fontWeight="600">{stat?.currentContest.league ?? ' '}</Box>
      <Box
        pos="absolute"
        top="50%"
        left="18px"
        transform="translateY(-50%)"
        as={LeftArrowIcon}
        opacity={!disabled && stat?.previousContest ? '1' : '0.3'}
        onClick={!disabled && stat?.previousContest ? () => onPrev(stat.previousContest!.id) : undefined}
      >
        Prev
      </Box>

      <Box
        pos="absolute"
        top="50%"
        right="18px"
        transform="translateY(-50%)"
        as={RightArrowIcon}
        opacity={!disabled && stat?.nextContest ? '1' : '0.3'}
        onClick={!disabled && stat?.nextContest ? () => onNext(stat.nextContest!.id) : undefined}
      >
        Next
      </Box>
    </Flex>
  );
};

export default LeaderboardHeader;
