import { useGetParticipantBoostersQuery } from 'api/contest';

import Boosters from 'components/booster/Boosters';

const AllBoostersPage = () => {
  // todo: why we do not use useBoosters hook here?
  const { data: boosters, isLoading } = useGetParticipantBoostersQuery(undefined);

  return <Boosters isLoading={isLoading} boosters={boosters} title="My Boosters" subTitle="Earn coins on top of PTS" />;
};

export default AllBoostersPage;
