import { Box, Grid } from '@chakra-ui/react';

import { useAppSelector } from 'hooks/app';

import ContestSlider from './ContestSlider';
import FeedbackChat from './FeedbackChat';

const AuthHome = () => {
  const user = useAppSelector((store) => store.auth.user)!;
  const { contest, previousContest, nextContest } = useAppSelector((store) => store.contest);

  if (!contest) return null;

  return (
    <Grid w="100%" h="100%" gridTemplateRows="auto 1fr" pb="20px">
      <Box textAlign="center" color="white">
        <Box fontSize="14px" fontWeight="600" opacity="0.6">
          wsg
        </Box>
        <Box fontSize="20px" fontWeight="800" lineHeight="24px">
          {user.username}
        </Box>
      </Box>

      <ContestSlider previous={previousContest} next={nextContest} current={contest} />
      {user && <FeedbackChat user={user} />}
    </Grid>
  );
};

export default AuthHome;
