import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Box, Flex } from '@chakra-ui/react';
import routes from 'routes';

import { useAppSelector } from 'hooks/app';

import { ReactComponent as BoostersIcon } from 'icons/boosters-icon.svg';
import { ReactComponent as LogoIcon } from 'icons/logo.svg';

const hiddenRoutes = [routes.builder, routes.boosters, routes.allBoosters, routes.leaderboard];

const Header = () => {
  const isAuthicated = useAppSelector((state) => state.auth.isAuthicated);
  const participation = useAppSelector((state) => state.contest.participation);
  const { pathname } = useLocation();

  const isHidden = useMemo(() => {
    if (
      (pathname.startsWith(routes.home) && isAuthicated) ||
      hiddenRoutes.some((r) => pathname.startsWith(r)) ||
      (pathname.startsWith(routes.squad) && participation?.status === 'completed')
    ) {
      return true;
    }
    return false;
  }, [participation, pathname, isAuthicated]);

  const isWide = useMemo(() => {
    if (pathname === '/' && isAuthicated) {
      return true;
    }
    if (participation?.status === 'completed') {
      return true;
    }
    if (pathname.startsWith(routes.leaderboard)) {
      return true;
    }
    return false;
  }, [isAuthicated, pathname]);

  return (
    <Flex
      alignItems="center"
      justify="center"
      overflow={isHidden && !isWide ? 'hidden' : 'initial'}
      h={isHidden ? '0px' : '48px'}
      zIndex="100"
      pos="relative"
    >
      <Box
        as={Link}
        to={routes.home}
        __css={
          isWide
            ? {
                pos: 'absolute',
                top: '8px',
                left: '16px',
              }
            : undefined
        }
      >
        <LogoIcon />
      </Box>
      {isWide && isAuthicated && pathname === '/' && (
        <Box pos="absolute" top="8px" right="16px" as={Link} to={routes.allBoosters}>
          <BoostersIcon />
        </Box>
      )}
    </Flex>
  );
};

export default Header;
