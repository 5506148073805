import { ReactNode } from 'react';

import { Box, Button, Flex, Text } from '@chakra-ui/react';

interface TutorialProps {
  title: ReactNode;
  content: ReactNode;
  onOk?: () => void;
}

const Tutorial = ({ onOk, title, content }: TutorialProps) => {
  return (
    <Box
      px="24px"
      pt="8px"
      position="fixed"
      top="0"
      left="0"
      w="100%"
      h="100%"
      zIndex={1}
      background="rgba(36,36,36,.9)"
      backdropFilter={'blur(4px)'}
    >
      <Flex alignItems="center" justifyContent="center" flexDirection="column" h="100%" gap="32px">
        {title}
        {content}
        <Button onClick={onOk}>ok</Button>
      </Flex>
    </Box>
  );
};

export default Tutorial;
