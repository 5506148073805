import { FC } from 'react';

import { Box, Fade, Flex, keyframes } from '@chakra-ui/react';

const shineAnimation = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
`;

const LeaderboardHeaderSkeleton: FC = () => {
  return (
    <Fade in={true}>
      <Flex
        justify="space-between"
        flexDir="row"
        align="center"
        fontSize="14px"
        lineHeight="16.8px"
        pos="relative"
        mt="18px"
      >
        <Box
          w="33.6px"
          h="33.6px"
          borderRadius="4px"
          transition="opacity 0.2s linear"
          backgroundImage="linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.1) 50%, rgba(255,255,255,0) 100%)"
          backgroundSize="400px 100%"
          animation={`${shineAnimation} 1.5s infinite linear`}
        ></Box>
        <Box
          w="90px"
          h="33.6px"
          borderRadius="4px"
          transition="opacity 0.2s linear"
          backgroundImage="linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.1) 50%, rgba(255,255,255,0) 100%)"
          backgroundSize="400px 100%"
          animation={`${shineAnimation} 1.5s infinite linear`}
        ></Box>
        <Box
          w="33.6px"
          h="33.6px"
          borderRadius="4px"
          transition="opacity 0.2s linear"
          backgroundImage="linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.1) 50%, rgba(255,255,255,0) 100%)"
          backgroundSize="400px 100%"
          animation={`${shineAnimation} 1.5s infinite linear`}
        ></Box>
      </Flex>
    </Fade>
  );
};

export default LeaderboardHeaderSkeleton;
