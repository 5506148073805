import { FC, useEffect, useState } from 'react';

import { Box, Flex } from '@chakra-ui/react';
import { Booster } from 'models';

import { Loader } from 'components/Loader';
import { SpinnerProps } from 'components/pages/BuilderPage/types';

import useSpinner from 'hooks/useSpinner';

import BoosterCards from './BoosterCards';
import BoosterPack from './BoosterPack';

const preloadImage = (src: string) => {
  const img = new Image();
  img.src = src;
};

const firstSpinImage =
  'https://clutchpoints-store-media.s3.amazonaws.com/SBC+media/Starter+pack/spin-cover_pack-nba.webp';
const notFirstSpinImage =
  'https://clutchpoints-store-media.s3.amazonaws.com/SBC+media/Starter+pack/spin-cover_card-nba.webp';

preloadImage(firstSpinImage);
preloadImage(notFirstSpinImage);

const Spinner: FC<SpinnerProps> = ({ hadFirstSpin, onClose }) => {
  const [isAvailableToGetBooster, setIsAvailableToGetBooster] = useState(false);
  const [boosters, setBoosters] = useState<Booster[] | null>(null);
  const { data, isLoading, isFetching } = useSpinner(isAvailableToGetBooster);

  useEffect(() => {
    if (data) {
      setBoosters([...data.boosters]);
    }
  }, [data]);

  if (isLoading || isFetching) {
    return (
      <Box position="fixed" top="0" left="0" w="100%" h="100%" zIndex={1} background="#242424">
        <Loader />;
      </Box>
    );
  }

  return (
    <Box px="12px" pt="8px" position="fixed" top="0" left="0" w="100%" h="100%" zIndex={1} background="#242424">
      <Flex alignItems="center" justifyContent="center" flexDirection="column" h="100%" gap="15px">
        {!boosters ? (
          <BoosterPack
            onPackClick={() => setIsAvailableToGetBooster(true)}
            isFirstSpin={!hadFirstSpin}
            image={hadFirstSpin ? notFirstSpinImage : firstSpinImage}
          />
        ) : (
          <BoosterCards onClose={onClose} boosters={boosters} />
        )}
      </Flex>
    </Box>
  );
};

export default Spinner;
