import { FC } from 'react';

import { Box, BoxProps } from '@chakra-ui/react';
import { Card } from 'models';

import usePlayer from 'hooks/usePlayer';

type CardPlaceholderProps = {
  card?: Card;
} & BoxProps;

const CardPlaceholder: FC<CardPlaceholderProps> = ({ card, ...props }) => {
  const player = usePlayer(card ?? null);
  const headshot = player?.headshotThumbnails?.small ?? player?.headshotThumbnails?.medium ?? player?.headshot;

  return (
    <Box
      w="60px"
      h="60px"
      backgroundColor={card ? 'white' : '#343434'}
      borderRadius="14px"
      backgroundImage={card ? `url(${headshot})` : ''}
      backgroundSize="cover"
      backgroundPosition="center center"
      {...props}
    />
  );
};

export default CardPlaceholder;
