import { FC, useMemo } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Flex, Img } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { Contest } from 'models';
import routes from 'routes';

import { getEventStatusString } from './utils';

const SliderItem: FC<{ contest: Contest }> = ({ contest, ...props }) => {
  const navigate = useNavigate();
  const title = useMemo(() => {
    if (!contest) return '';
    const startsAtNewYork = dayjs(contest.startsAt).tz('America/New_York');
    const timeOfDay = startsAtNewYork.hour() >= 18 ? 'Night' : 'Day';
    const formattedDate = startsAtNewYork.format('M/D');
    return `${contest.league.toUpperCase()} ${timeOfDay} ${formattedDate}`;
  }, [contest]);

  const statusTime = useMemo(() => {
    if (!contest) return '';
    return getEventStatusString(contest);
  }, [contest]);

  const status = useMemo(() => {
    if (!contest) return '';
    if (contest.status === 'active') {
      return 'live';
    }
    if (contest.status === 'completed') {
      return 'completed ✓';
    }
    return contest.status;
  }, [contest]);

  const onButtonClick = (location: string, message: string | undefined) => {
    ReactGA.event({
      category: 'Contest',
      action: 'click',
      label: message,
    });

    navigate(location);
  };

  const button = useMemo(() => {
    if (contest.status === 'completed') {
      return (
        <Button
          w="205px"
          onClick={() => onButtonClick(`${routes.leaderboard}?contestId=${contest.id}`, 'Leaderbord click')}
          data-cy="completed-contest-btn"
        >
          leaderboard
        </Button>
      );
    }
    if (contest.status === 'scheduled') {
      return (
        <Button w="205px" isDisabled>
          my squad
        </Button>
      );
    }

    if (contest.status === 'locked') {
      return (
        <Button
          w="205px"
          onClick={() => onButtonClick(routes.squad, 'My Squad Click On Locked Contest')}
          data-cy="locked-contest-btn"
        >
          my squad
        </Button>
      );
    }

    if (contest.status === 'active') {
      return (
        <Button
          w="205px"
          onClick={() => onButtonClick(routes.squad, 'My Squad Click On Active Contest')}
          data-cy="active-contest-btn"
        >
          my squad
        </Button>
      );
    }
  }, [contest]);
  return (
    <Box display="flex" justifyContent="center" flexDir="column" {...props}>
      <Box textAlign="center">
        <Img mx="auto" src={contest.image} width="160px" />
        <Box
          textTransform="lowercase"
          fontSize="13px"
          fontWeight="800"
          lineHeight="16px"
          mt="8px"
          color={contest.status === 'active' || contest.status === 'completed' ? '#F83C3C' : 'white'}
        >
          {status}
        </Box>
        <Box fontSize="23px" fontWeight="800" lineHeight="27px" mt="4px">
          {title}
        </Box>
        <Box fontSize="15px" fontWeight="800" lineHeight="18px" mt="8px">
          {statusTime}
        </Box>
        <Flex justify="center" mt="23px">
          {button}
        </Flex>
      </Box>
    </Box>
  );
};

export default SliderItem;
