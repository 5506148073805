import { FC, useEffect, useState } from 'react';

import { Box, Flex, Grid, SlideFade } from '@chakra-ui/react';
import { ContestParticipant, LeaderboardStat } from 'models';

import LeaderboardRow from './LeaderboardRow';
import MySquad from './MySquad';
import NoParticipants from './NoParticipants';

type Props = {
  stat: LeaderboardStat;
  participant?: ContestParticipant;
  showLeaderboard?: boolean;
  showMySquad?: boolean;
};

const Leaderboard: FC<Props> = ({ stat, showLeaderboard = true, showMySquad = true, participant }) => {
  const participants = stat.participants;
  const [isOpen, setIsOpen] = useState<boolean[]>([]);

  useEffect(() => {
    if (participants) {
      const openBoosters = async () => {
        for (let i = 0; i < participants.length; i++) {
          await new Promise((resolve) => setTimeout(resolve, 100));
          setIsOpen((prev) => {
            const newIsOpen = [...prev];
            newIsOpen[i] = true;
            return newIsOpen;
          });
        }
      };
      openBoosters();
    }
  }, [participants, stat.currentContest]);

  return (
    <Flex flexDirection="column" flex="1">
      {participant && showMySquad && <MySquad participant={participant} contestStatus={stat.currentContest.status} />}
      {showLeaderboard && (
        <Box h="100%">
          {participants.length > 0 ? (
            participants.map((p, index) => (
              <SlideFade key={p.id} in={isOpen[index]} offsetY="10px" unmountOnExit>
                <LeaderboardRow position={index} participant={p} key={p.id} />
              </SlideFade>
            ))
          ) : (
            <>{stat.nextContest !== undefined && <NoParticipants nextContest={stat.nextContest} />}</>
          )}
        </Box>
      )}
    </Flex>
  );
};

export default Leaderboard;
