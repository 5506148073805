import { FC } from 'react';

import { Box, Fade, Flex, Grid } from '@chakra-ui/react';
import { Contest, LeaderboardStat } from 'models';

import NextGame from 'components/layout/NextGame';

import { ReactComponent as LockIcon } from 'icons/lock.svg';

const NotStartedMessage: FC<{ stat: LeaderboardStat; nextContest: Contest | null }> = ({ stat, nextContest }) => {
  return (
    <Grid pos="relative" pt="8px" pb="20px" gridTemplateRows="1fr auto" flex="3">
      <Flex flexDir="column" align="center" justify="center" gap="16px" px="12px">
        <Fade in={true}>
          <Box as={LockIcon} mx="auto" mb="8px" />
          <Box fontSize="14px" fontWeight="600" lineHeight="16.8px" opacity="0.8" textAlign="center">
            Today's games are on.
            <br />
            Results and places tomorrow morning.
          </Box>
        </Fade>
      </Flex>

      {/* {nextContest && <NextGame data={nextContest} />} */}
    </Grid>
  );
};

export default NotStartedMessage;
