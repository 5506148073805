import { FC, useEffect, useState } from 'react';

import { Box, Image, keyframes } from '@chakra-ui/react';

import { BoosterPackProps } from 'components/pages/BuilderPage/types';

const bounceAnimation = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
`;

const fadeInAnimation = keyframes`
  0% { 
    scale: 0.8;
    opacity: 0;
  }
 
  100% { 
    scale: 1;
    opacity: 1; 
  }
`;

const shadowFadeInAnimation = keyframes`
  0% { 
    opacity: 0; 
  }
 
  100% { 
    opacity: 0.5; 
  }
`;

const fadeOutAnimation = keyframes`
  0% { 
    opacity: 1; 
    scale: 1;
  }
 
  100% { 
    opacity: 0; 
    scale: 0.9;
  }
`;

const BoosterPack: FC<BoosterPackProps> = ({ onPackClick, image }) => {
  const [isClicked, setIsClicked] = useState(false);

  const onImageClickHandler = () => {
    if (isClicked) return;
    setIsClicked(true);
    onPackClick?.();
  };

  return (
    <>
      <Box
        width="100%"
        position="relative"
        display="flex"
        alignItems="center"
        justifyContent="center"
        animation={`${bounceAnimation} 2s ease-in-out infinite .8s ${isClicked ? ', ' + fadeOutAnimation + ' .8s ease forwards' : ''}`}
        marginBottom="20px"
        onClick={onImageClickHandler}
      >
        <Image
          src={image}
          alt="booster pack"
          height="auto"
          width="320px"
          position="absolute"
          top="50%"
          left="50%"
          filter="blur(80px)"
          zIndex="0"
          opacity={0.6}
          transform="translate(-50%, -50%)"
          animation={`${shadowFadeInAnimation} 2s ease forwards`}
          loading="eager"
        />
        <Image
          src={image}
          alt="booster pack"
          height="auto"
          width="320px"
          position="relative"
          zIndex="1"
          animation={`${fadeInAnimation} .8s ease forwards`}
          loading="eager"
        />
      </Box>
      <Box
        animation={isClicked ? `${fadeOutAnimation} .8s ease forwards` : ''}
        textAlign="center"
        fontSize="20px"
        lineHeight="24px"
        fontWeight="800"
      >
        tap to open 👆
      </Box>
    </>
  );
};

export default BoosterPack;
