import { ReactElement, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import {
  Navigate,
  Route,
  Routes,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigate,
  useNavigationType,
} from 'react-router-dom';

import { Box, Grid } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import Cookies from 'js-cookie';
import routes from 'routes';

import { useGetUserQuery } from 'api/auth';

import Header from 'components/layout/Header';
import Protected from 'components/layout/Protected';
import AllBoostersPage from 'components/pages/AllBoostersPage';
import BoostersPage from 'components/pages/BoostersPage';
// import BuilderPage from 'components/pages/BuilderPageInProgress/BuilderPage';
import BuilderPage from 'components/pages/BuilderPage/BuilderPage';
import HomePage from 'components/pages/HomePage';
import LeaderboardPage from 'components/pages/LeaderboardPage';
import LogoutPage from 'components/pages/LogoutPage';
import SignInPage from 'components/pages/SignInPage';
import SquadPage from 'components/pages/SquadPage';

import { useAppDispatch, useAppSelector } from 'hooks/app';
import useContest from 'hooks/useContest';

import { setIsAuthicated, setUser } from 'store/slices/auth';

const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;

function App() {
  const [token, setToken] = useState(Cookies.get('sbc_access_token'));
  const { isLoading, data, error: userError } = useGetUserQuery(undefined, { skip: !token });
  const { isLoading: isContestLoading } = useContest();
  const dispatch = useAppDispatch();
  const isUserLoading = useAppSelector((state) => state.auth.isLoading);
  const location = useLocation();
  const navigate = useNavigate();
  const [inited, setInited] = useState(false);

  useEffect(() => {
    if (!TRACKING_ID) return;
    ReactGA.initialize(TRACKING_ID);
    ReactGA.send({ hitType: 'pageview', page: location.pathname, title: document.title });
  }, [location.pathname]);

  useEffect(() => {
    if (!Cookies.get('sbc_access_token')) {
      const queryParams = new URLSearchParams(location.search);
      const token = queryParams.get('token');
      if (token) {
        Cookies.set('sbc_access_token', token, { secure: true, expires: 60 * 60 * 24 * 7 });
        setToken(token);
        navigate(location.pathname, { replace: true });
        return;
      }
    }
    if (userError) {
      Sentry.captureException(userError);
    }
    if (isLoading) return;
    dispatch(setIsAuthicated(!!data));
    dispatch(setUser(data));
    setInited(true);
  }, [isLoading, data, userError]);

  if (isUserLoading || isContestLoading || !inited) return null;

  return (
    <Grid gridTemplateRows="auto 1fr" id="app" pt="8px" userSelect="none">
      <Header />

      <Routes>
        <Route path={routes.home} element={<HomePage />} />
        <Route path={routes.signIn} element={<SignInPage />} />
        <Route element={<Protected />}>
          <Route path={routes.builder} element={<BuilderPage />} />
          <Route path={routes.boosters} element={<BoostersPage />} />
          <Route path={routes.allBoosters} element={<AllBoostersPage />} />
          <Route path={routes.squad} element={<SquadPage />} />
          <Route path={routes.logout} element={<LogoutPage />} />
        </Route>
        <Route path={routes.leaderboard} element={<LeaderboardPage />} />
        <Route path="*" element={<Navigate to={routes.home} replace />} />
      </Routes>
    </Grid>
  );
}

export default App;
