import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Box, Text } from '@chakra-ui/react';

import { useGetContestParticipantQuery } from 'api/contest';
import { useGetLeaderboardQuery } from 'api/leaderboard';

import Leaderboard from 'components/leaderboard/Leaderboard';
import LeaderboardHeader from 'components/leaderboard/LeaderboardHeader';
import LeaderboardSkeleton from 'components/leaderboard/LeaderboardSkeletol';
import NotStartedMessage from 'components/leaderboard/NotStartedMessage';

import useContest from 'hooks/useContest';

const LeaderboardPage: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsContestId = searchParams.get('contestId');

  const [contestId, setContestId] = useState(0);
  const { isFetching: isContestFetching, contest, nextContest, participation } = useContest();
  const { data: stat, isFetching: isLeaderbordFetching } = useGetLeaderboardQuery(contestId, { skip: !contestId });
  const {
    data: participant,
    isFetching: isParticipationFetching,
    isLoading: isParticipationLoading,
    isError,
    error,
  } = useGetContestParticipantQuery(contestId, { skip: !contestId || contestId === 0 });

  useEffect(() => {
    if (searchParamsContestId) {
      setContestId(Number(searchParamsContestId));
    } else {
      setContestId(contest?.id || 0);
    }
  }, [searchParamsContestId, contest]);

  const handleChangeContestId = (id: number) => {
    setSearchParams((sp) => {
      const newParams = new URLSearchParams(sp);
      newParams.set('contestId', `${id}`);
      return newParams;
    });
  };

  const isFetching = isContestFetching || isLeaderbordFetching || isParticipationFetching;
  const isLocked = stat?.currentContest.status === 'locked';
  const isCompleted = stat?.currentContest.status === 'completed';
  const isLoading = !isFetching && !!stat;

  return (
    <Box display="flex" flexDirection="column" h="100%" gridTemplateRows="auto auto 1fr" px="12px">
      <Text variant="pageTitle">leaderboard</Text>
      <LeaderboardHeader
        disabled={isFetching}
        stat={stat}
        onNext={handleChangeContestId}
        onPrev={handleChangeContestId}
      />
      {isFetching && <LeaderboardSkeleton />}

      {isLoading && (
        <>
          {!isLocked && (
            <Leaderboard
              key={stat?.currentContest.id}
              stat={stat}
              participant={participant}
              showLeaderboard={!isLocked}
              showMySquad={
                ((isLocked && participant?.status === 'completed') ||
                  (isCompleted && participant?.status === 'completed')) &&
                !isError
              }
            />
          )}
          {isLocked && <NotStartedMessage stat={stat} nextContest={nextContest} />}
        </>
      )}
    </Box>
  );
};

export default LeaderboardPage;
