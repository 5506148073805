import { FC, useCallback, useEffect } from 'react';
import AppleSignin from 'react-apple-signin-auth';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { Box, BoxProps, Flex, Text } from '@chakra-ui/react';
import FacebookLogin from '@greatsumini/react-facebook-login';
import Cookies from 'js-cookie';
import routes from 'routes';
import { parseJWT } from 'utils/JWTencode';

import { useLazyGetUserQuery, useSignInAppleMutation, useSignInFBMutation, useSignInGoogleMutation } from 'api/auth';

import { setIsAuthicated, setUser } from 'store/slices/auth';

import { ReactComponent as AppleIcon } from 'icons/apple.svg';
import { ReactComponent as FbIcon } from 'icons/fb.svg';
import { ReactComponent as GoogleIcon } from 'icons/google.svg';

const fbAppId = '1075306005823517';
const appleClientId = 'com.clutchpoints.ClutchPoints2';
const googleClientId = '609550829109-m5gdf8j2f264bk47dm4kqplhjnucichj.apps.googleusercontent.com';

const SocialSignIn: FC<BoxProps> = (props) => {
  useEffect(() => {
    (window as any).google.accounts.id.initialize({
      client_id: googleClientId,
      callback: handleCredentialResponse,
    });
  }, []);

  const [getUserMutation] = useLazyGetUserQuery();
  const dispatch = useDispatch();

  const onSuccess = useCallback(async (token: string) => {
    Cookies.set('sbc_access_token', token, { secure: true, expires: 60 * 60 * 24 * 7 });
    const { data, error } = await getUserMutation();
    if (!error) {
      dispatch(setIsAuthicated(!!data));
      dispatch(setUser(data));
    }
  }, []);

  const [signInApple] = useSignInAppleMutation();
  const [signInFB] = useSignInFBMutation();
  const [signInGoogle] = useSignInGoogleMutation();

  const handleCredentialResponse = async (response: any) => {
    const { data, error } = await signInGoogle({
      credential: response.credential,
    });
    if (!error) {
      onSuccess(data.token);
    }
  };

  const openGooglePopup = () => {
    (window as any).google.accounts.id.prompt();
  };

  return (
    <Box {...props}>
      <Box>
        <Text textAlign="center" variant="text1">
          or continue with
        </Text>
        <Flex mt="16px" justify="center" gap="16px">
          <GoogleIcon onClick={openGooglePopup} />

          <AppleSignin
            uiType="light"
            authOptions={{
              clientId: appleClientId,
              scope: 'email name',
              redirectURI: window.location.origin + '/',
              state: 'state',
              nonce: 'nonce',
              usePopup: true,
            }}
            noDefaultStyle={false}
            onSuccess={async (response?: any) => {
              const appleData = parseJWT(response.authorization.id_token);
              const { data, error } = await signInApple({
                id: appleData.payload.sub,
                identityToken: response.authorization.id_token,
              });
              if (!error) {
                onSuccess(data.accessToken);
              }
            }}
            onError={(error?: Error) => console.error(error)}
            render={(props: any) => <AppleIcon {...props} />}
          />

          <FacebookLogin
            appId={fbAppId}
            autoLoad={false}
            fields="name,email,picture"
            onSuccess={async (response) => {
              const { data, error } = await signInFB({
                accessToken: response.accessToken,
                facebookId: response.userID,
              });
              if (!error) {
                onSuccess(data.accessToken);
              }
            }}
            onFail={(error) => {
              console.error('Login Failed!', error);
            }}
            render={(renderProps) => <FbIcon onClick={renderProps.onClick} />}
          />
        </Flex>
      </Box>
      <Text
        variant="text2"
        textAlign="center"
        maxW="310px"
        mx="auto"
        mt="40px"
        sx={{
          '& a': {
            color: 'white',
          },
        }}
      >
        By tapping “Next” or continuing using Services you agree to the our{' '}
        <Box as={Link} to={routes.home}>
          Terms of Use
        </Box>{' '}
        and{' '}
        <Box as={Link} to={routes.home}>
          Privacy Policy
        </Box>
        .
      </Text>
    </Box>
  );
};

export default SocialSignIn;
