import { FC, useEffect, useState } from 'react';

import { Fade, Flex, Text } from '@chakra-ui/react';

import { Loader } from 'components/Loader';

import useBuilder from 'hooks/useBuilder';

import Builder from './ui/Builder';
import Spinner from './ui/Spinner';
import Tutorial from './ui/Tutorial';

const BuilderPage: FC = () => {
  const [isSpinOpen, setIsSpinOpen] = useState<boolean | null>(null);

  const [showTutorial, setShowTutorial] = useState(false);

  const {
    cardsWithStatus,
    selectedCards,
    card,
    contest,
    benchCard,
    chooseCard,
    animation,
    player,
    participation,
    isAnimating,
    isLoading,
    error,
    isFetching,
  } = useBuilder();

  useEffect(() => {
    const lastShown = localStorage.getItem('sbc_tutorial_1');
    const today = new Date();

    if (lastShown) {
      const lastShownDate = new Date(lastShown);
      const diffTime = Math.abs(today.getTime() - lastShownDate.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays >= 3) {
        setShowTutorial(true);
        localStorage.setItem('sbc_tutorial_1', today.toDateString());
      }
    } else {
      setShowTutorial(true);
      localStorage.setItem('sbc_tutorial_1', today.toDateString());
    }
  }, []);

  useEffect(() => {
    if (isSpinOpen === null) {
      if (participation) {
        setIsSpinOpen(participation.canSpinToday);
      }
    }
  }, [participation, isSpinOpen, setIsSpinOpen]);

  const handleTutorialToggle = () => {
    setShowTutorial((prev) => !prev);
  };

  if (!participation) {
    return <Loader />;
  }

  return (
    <>
      {isSpinOpen !== null && (
        <>
          {isSpinOpen && <Spinner onClose={() => setIsSpinOpen(false)} hadFirstSpin={!!participation?.hadFirstSpin} />}
          {!isSpinOpen && (
            <Fade
              unmountOnExit
              in={showTutorial}
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 999,
              }}
            >
              <Tutorial
                onOk={handleTutorialToggle}
                title={
                  <Flex alignItems="center" justifyContent="center" flexDirection="column" gap="12px">
                    <Text variant="tutorialPhase">phase 1 of 2</Text>
                    <Text variant="tutorialTitle">how to play SBC</Text>
                  </Flex>
                }
                content={
                  <>
                    <Text variant="tutorialText">
                      🃏 <b>Get 7 players,</b> revealed 1-by-1
                    </Text>
                    <Text variant="tutorialText">
                      ⭐ <b>Start 3</b> to rack up PTS tonight
                    </Text>
                    <Text variant="tutorialText">
                      🔄  <b>Bench a backup</b> to sub in if a starter DNP or scored lower
                    </Text>
                    <Text variant="tutorialText">
                      ✂️ <b>Cut 3</b> along the way
                    </Text>
                  </>
                }
              />
            </Fade>
          )}

          <Builder
            cardsWithStatus={cardsWithStatus}
            selectedCards={selectedCards}
            card={card}
            contest={contest}
            benchCard={benchCard}
            chooseCard={chooseCard}
            animation={animation}
            player={player}
            participation={participation}
            isAnimating={isAnimating}
            showTutorial={handleTutorialToggle}
          />
        </>
      )}
    </>
  );
};

export default BuilderPage;
