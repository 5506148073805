import React, { FC, useEffect, useState } from 'react';

import { Box, Button, Flex, Text, keyframes } from '@chakra-ui/react';

import Spinner from 'components/Spinner';
import BoosterCard from 'components/booster/BoosterCard';
import { BoosterCardsProps } from 'components/pages/BuilderPage/types';

const fadeInCard = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeInPerspective = keyframes`
  0% {
  opacity: 0;
    transform: scale(0.5) perspective(90px) rotateY(8deg);
  }
  100% {
    opacity: 1;
    transform: scale(1)  perspective(90px) rotateY(0deg);
  }
`;

const fadeOutCard = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const BoosterCards: FC<BoosterCardsProps> = ({ boosters, ...props }) => {
  const [activeBooster, setActiveBooster] = useState<number | null>(null);
  const [removingBooster, setRemovingBooster] = useState<number | null>(null);

  const handleNextClick = (id: number) => {
    setRemovingBooster(activeBooster);
    setTimeout(() => {
      setActiveBooster(id);
      setRemovingBooster(null);
    }, 800);
  };

  const handleOnClose = () => {
    setRemovingBooster(activeBooster);
    setTimeout(() => {
      setRemovingBooster(null);
      props.onClose && props.onClose();
    }, 800);
  };

  useEffect(() => {
    if (boosters) {
      setActiveBooster(boosters[0].id);
    }
  }, [boosters]);

  return (
    <>
      <Box w="100%" padding="13px 0" zIndex={2}>
        <Text
          fontSize="18px"
          fontWeight="800"
          lineHeight="21.6px"
          variant="boosterTitle"
          textAlign="center"
          color="#fff"
        >
          yooo got new boost! 🚀
        </Text>
      </Box>
      <Flex
        flexDirection="column"
        animation={`${fadeInCard} .8s ease-in`}
        h="100%"
        alignItems="center"
        justifyContent="center"
      >
        {boosters?.map((booster, index) => (
          <React.Fragment key={booster.id}>
            {activeBooster === booster.id && (
              <Flex
                flexDirection="column"
                gap="32px"
                animation={`${removingBooster === booster.id ? fadeOutCard : fadeInCard} .5s ease-in forwards`}
              >
                <Flex
                  transformOrigin="50% 50%"
                  alignItems="center"
                  justifyContent="center"
                  position="relative"
                  animation={`${fadeInPerspective} .8s ease-in forwards`}
                >
                  <BoosterCard
                    key={booster.id}
                    booster={booster}
                    aspectRatio={'1/1.5'}
                    {...props}
                    position="relative"
                    zIndex={1}
                    type="spin"
                    w="200px"
                  />
                  {booster.isGlowing && (
                    <Spinner
                      position="absolute"
                      top="50%"
                      left="50%"
                      transform="translate(-50%, -50%)"
                      css={`
                        svg,
                        path {
                          fill: ${booster.backgroundColor};
                        }
                      `}
                    />
                  )}
                </Flex>

                <Flex flexDirection="column" gap="16px" zIndex={2}>
                  {boosters.length > 1 && (
                    <Text fontSize="14px" fontWeight="500" textAlign="center" marginBottom="0" opacity="0.5">
                      Card {index + 1} of {boosters.length}
                    </Text>
                  )}
                  <Flex flexDirection="column" gap="8px">
                    <Text fontSize="24px" fontWeight="800" lineHeight="29px" textAlign="center" color="#fff">
                      {booster.name}
                    </Text>
                    <Text
                      fontSize="14px"
                      fontWeight="500"
                      lineHeight="18px"
                      textAlign="center"
                      color="rgba(255, 255, 255, 0.8)"
                      css={{ textWrap: 'balance' }}
                    >
                      {/* Multiplies total squad points by 2.0x. Bonus: Personal fouls earn 25 coins each. */}
                      {booster.description}
                    </Text>
                  </Flex>

                  <Text
                    fontSize="14px"
                    fontWeight="500"
                    lineHeight="18px"
                    textAlign="center"
                    color="rgba(255, 255, 255, 0.8)"
                    opacity={0.5}
                    css={{ textWrap: 'balance' }}
                  >
                    Apply after building the squad.
                    <br />
                    Single-use item · {booster.league.toUpperCase()}
                  </Text>
                </Flex>
                {index !== boosters.length - 1 ? (
                  <Flex
                    justifyContent="center"
                    onClick={() => {
                      if (boosters[index + 1]) {
                        handleNextClick(boosters[index + 1].id);
                      }
                    }}
                  >
                    <Button>next</Button>
                  </Flex>
                ) : (
                  <Flex justifyContent="center">
                    <Button onClick={() => handleOnClose()}>to the game</Button>
                  </Flex>
                )}
              </Flex>
            )}
          </React.Fragment>
        ))}
      </Flex>
    </>
  );
};

export default BoosterCards;
