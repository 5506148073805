import { Box, Flex, Img } from '@chakra-ui/react';
import { Card, ContestParticipant, ContestStatus } from 'models';

import DetailedCard from 'components/builder/DetailedCard';

import SquadFooter from './SquadFooter';

interface SquadDetailedInfoProps {
  participation: ContestParticipant;
  cards: Card[];
  contestStatus?: ContestStatus;
}

const SquadDetailedInfo = ({ participation, cards, contestStatus }: SquadDetailedInfoProps) => {
  const place = participation.place ?? 'TBA';
  const displayPlace = contestStatus === 'completed' ? place : 'TBA';

  return (
    <>
      <Flex mt="20px" gap="24px" alignItems="flex-start" justify="center" zIndex={50} position="relative">
        <Box textAlign="center">
          <Flex align="center" h="29px">
            {contestStatus === 'completed' ? (
              <>
                <Img w="20px" h="20px" src="/coin.png" />
                <Box fontSize="24px" fontWeight="800" lineHeight="24px">
                  {participation.totalPoints ?? 'TBA'}
                </Box>
              </>
            ) : (
              <Box fontSize="24px" fontWeight="800" lineHeight="24px">
                TBA
              </Box>
            )}
          </Flex>
          <Box fontSize="12px" fontWeight="600" lineHeight="14.4px" color="#FFFFFFCC">
            earnings
          </Box>
        </Box>
        <Box textAlign="center">
          <Flex align="center" justify="center" fontSize="24px" fontWeight="800" lineHeight="24px" h="29px">
            {displayPlace}
          </Flex>
          <Box fontSize="12px" fontWeight="600" lineHeight="14.4px" color="#FFFFFFCC">
            place
          </Box>
        </Box>
      </Flex>
      {contestStatus === 'completed' && (
        <>
          <Box mt="24px">
            {cards
              .filter((card) => card.status !== 'cut')
              .map((card, index, initialArray) => (
                <Box key={`card-${index}`} opacity={index === initialArray.length - 1 ? 0.5 : 1}>
                  <DetailedCard card={card} booster={participation.booster} key={card.id} />
                </Box>
              ))}
          </Box>
          <Box fontSize="14px" fontWeight="600" color="#FFFFFFCC" textAlign="center" mt="40px">
            Only 3 top players count
          </Box>
        </>
      )}
      <SquadFooter />
    </>
  );
};

export default SquadDetailedInfo;
