import { FC } from 'react';

import { Box, GridItem, GridItemProps, Img, Text } from '@chakra-ui/react';
import { Booster } from 'models';

type BoosterCardProps = {
  booster: Booster;
  type?: 'spin' | 'default';
  cardWidth?: string | number;
} & GridItemProps;

const BoosterCard: FC<BoosterCardProps> = ({ booster, onClick, type = 'default', ...props }) => {
  return (
    <GridItem {...props}>
      <Box
        pos="relative"
        bgImage={`url(${booster.image})`}
        bgSize="cover"
        bgPos="center center"
        width={'100%'}
        minHeight={'255px'}
        aspectRatio={'1/1.5'}
        bgColor={booster.backgroundColor ?? 'white'}
        boxShadow="0px 6px 50px 0px #000000CC"
        borderRadius="16px"
        transition="opacity 0.2s linear"
        onClick={onClick}
      >
        <Text
          color={booster.textColor ?? undefined}
          variant="boosterName"
          pos="absolute"
          top="12px"
          left="12px"
          right="12px"
        >
          {type === 'spin' ? booster.rarity : booster.name}
        </Text>
        <Text
          variant="boosterMultiplier"
          pos="absolute"
          bottom="16px"
          left="12px"
          right="12px"
          color={booster.textColor ?? undefined}
        >
          {booster.pointsMultiplier} x PTS
          <br />
          {booster.attributeMultiplier} x {booster.attributeDisplay?.toUpperCase()}
          <br />
        </Text>
        {booster.icon && (
          <Img src={booster.icon} pos="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" />
        )}
      </Box>
    </GridItem>
  );
};

export default BoosterCard;
