import dayjs from 'dayjs';
import { Duration } from 'dayjs/plugin/duration';
import { Contest } from 'models';

export function getEventStatusString(contest: Contest) {
  const now = dayjs().tz('America/New_York');
  const locksAtNewYork = dayjs(contest.status === 'scheduled' ? contest.startsAt : contest.locksAt).tz(
    'America/New_York'
  );
  const locksDiff = locksAtNewYork.diff(now);
  const durationUntilLocks = dayjs.duration(locksDiff);

  const formatDuration = (duration: Duration) => {
    const days = Math.floor(duration.asDays());
    const hours = Math.floor(duration.asHours() % 24);
    const minutes = duration.minutes();

    if (days > 0) {
      return `${days}d ${hours}h`;
    } else if (hours > 0) {
      return `${hours}h ${minutes}m`;
    } else {
      return `${minutes}m`;
    }
  };
  if (contest.status === 'completed') {
    return 'results are in';
  } else if (contest.status === 'locked') {
    return 'results are pending';
  } else if (contest.status === 'active') {
    return `locks in ${formatDuration(durationUntilLocks)}`;
  } else if (contest.status === 'scheduled') {
    return `opens in ${formatDuration(durationUntilLocks)}`;
  }

  return 'unknown status';
}
